import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CartFacade } from '../../+state';
import { CartItem, CartItemsByVendor, CartResponse } from '../../models';
import { CommonModule } from '@angular/common';
import { CartTracking, CartProduct } from '@ea/products';
import { CookieService } from '@ea/auth';

declare const SR: any;

@Component({
  selector: 'ea-cart-totals',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cart-totals.component.html',
  styleUrls: ['./cart-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartTotalsComponent implements OnInit {
  cart$?: Observable<CartResponse>;
  // estimatedItemsTaxes$?: Observable<number>;
  // estimatedShippingCost$?: Observable<number>;
  // estimatedShippingTaxes$?: Observable<number>;
  total$?: Observable<number>;
  
  totalSave$?: Observable<number>;
  savetotalvalue: number=0;  
  estimatedShippingAmount:  number = 0; 
  itemsByVendor : any;
  totalToPay : number = 0;
  constructor(private cart: CartFacade, 
              private readonly cookies: CookieService) {}
  estimatedShipping(vendor: CartItemsByVendor[]) {
    return (
      vendor.reduce((acc, item) => {
        return acc + (item.shipTo?.shipMethod.cost.amount ?? 0);
      }, 0) ?? 0
    );
  }
  ngOnInit(): void {
    this.cart$ = this.cart.cart$;
    // this.estimatedItemsTaxes$ = this.cart.estimatedItemsTaxes$;
    // this.estimatedShippingCost$ = this.cart$.pipe(
    //   map((cart)=>{
    //     if
    //   })
    // )
    // this.estimatedShippingTaxes$ = this.cart.estimatedShippingTaxes$;
    this.trackSyneriseCart();
    this.totalSave$ = this.cart$.pipe(
      map((cart) => {
        return (
          cart.items?.reduce((acc, item) => {
            return (
              acc +
             ( (item.extra.orginalPrice.price * item.quantity) - item.totalPrice.amount)
            );
          }, 0) ?? 0
        );
      })
    );
  this.totalSave$.subscribe(x => this.savetotalvalue = x);
    this.total$ = this.cart$.pipe(
      map((cart) => {
          this.estimatedShippingAmount  = cart.stematedShipping;    
        return (
          cart.items?.reduce((acc, item) => {
            this.totalToPay =    acc +
            item.totalPrice.amount 
            return (
              acc +
              item.totalPrice.amount
            );
          }, 0) ?? 0
        );
      })
    );

  }

  
  trackSyneriseCart(){
    this.cart$?.subscribe((userCart: CartResponse) => {      
      const cartTracking: CartTracking = {} as CartTracking;
      cartTracking.products = [];
      userCart.items?.forEach((cartItem) => {
        let brandName = "";
        const brandNameObj = cartItem.attributes.filter(x => x.name == "brand")[0];
        if(brandNameObj){
          brandName = brandNameObj.value;
        }

        const productCategory = cartItem.extra?.breadcrumbsString?.value;
        let imageUrl = "";
        const imageUrlObj = cartItem.attributes.filter(x => x.name == "image_main_product")[0];
        if(imageUrlObj){
          imageUrl = imageUrlObj.value;
        }

        let imageAlt = "";
        const imageAltObj = cartItem.attributes.filter(x => x.name == "image_alt_main")[0];
        if(imageAltObj){
          imageAlt = imageAltObj.value;
        }

        const parentName = cartItem.extra?.parentName?.value || "";

        let displayName = "";
        const displayNameObj = cartItem.attributes.filter(x => x.name == "display_name")[0];
        if(displayNameObj){
          displayName = displayNameObj.value;
        }

        let vendorName = "";
        const vendorNameObj = cartItem.attributes.filter(x => x.name == "vendor_name")[0];
        if(vendorNameObj){
          vendorName = vendorNameObj.value;
        }

        const cartProduct: CartProduct = {
          sku: cartItem.sku,
          quantity: cartItem.quantity,
          brand: brandName,
          category: productCategory,
          unitPrice: cartItem.unitPrice,
          totalPrice: cartItem.totalPrice,
          imageUrl: imageUrl,
          imageAlt: imageAlt,
          parentName: parentName,
          displayName: displayName,
          vendorName: vendorName,
          totalPriceString: cartItem.totalPrice.amount.toFixed(2)
        }
        cartTracking.products.push(cartProduct);
      });

      cartTracking.totalAmount = userCart.totalAmount;
      cartTracking.totalQuantity = userCart.totalItems;
      cartTracking.itemIds = cartTracking.products.map(({ sku } : {sku:string}) => sku);
      cartTracking.client = this.cookies.get('_snrs_uuid') || '';
      
      //Synerise tracking for abandoned Carts
      SR.event.trackCustomEvent('cart.status', cartTracking, 'CartStatus');
    });
  }
}
